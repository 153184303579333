import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { IGroup } from "@app/shared/interfaces/accounting.interface";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IUser } from "../interfaces";
import { IEmployee } from "../interfaces/employee.interface";
import {
  IUserPropertyResponse,
  IUserPropertyTypes,
} from "../interfaces/property-type.interface";
import { IResponse, IResponseDTO } from "../interfaces/response.interface";
import { IOwnerReq, ITenantReq, UserReqBody, UserStore } from "@app/pages/users/+data-access";
import { AuthStore } from "@app/core/auth";

@Injectable({ providedIn: "root" })
export class UserService {

  #user = inject(AuthStore);
  
  constructor(private http: HttpClient) { }

  public getUserRoles() {
    return this.http.get(`${environment.apiUrl}/roles`);
  }

  public getUserPropertyPreferences(): Observable<IUserPropertyResponse> {
    return this.http.get<IUserPropertyResponse>(`${environment.apiUrl}/me`);
  }

  public updateUserPropertyPreferences(preferences: IUserPropertyTypes) {
    return this.http.patch(`${environment.apiUrl}/me`, preferences, {
      withCredentials: true,
    });
  }

  public getManagers(): Observable<IResponse<IUser[]>> {
    return this.http.get<IResponse<IUser[]>>(
      `${environment.apiUrl}/role-users/manager`,
      {
        withCredentials: true,
      }
    );
  }

  public getGroups(
    role: "agency" | "developer" | "management",
    limit: number = 10,
    page: number = 1,
    sort: string = "name",
    order: string = "asc",
    filters?: any,
  ): Observable<IResponseDTO<IGroup[]>> {
    let body = {
      limit,
      order,
      page,
      role,
      sort,
    };

    if (filters) body = {
      ...body,
      ...{ filters }
    };

    return this.http.post<IResponseDTO<IGroup[]>>(
      `${environment.apiUrl}/groups/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  searchUsers<SearchOptions = any>(body: SearchOptions) {
    return this.http.post<IResponseDTO<IUser[]>>(
      `${environment.apiUrl}/users/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  searchUsersByAssociation(
    property: string = "",
    roles?: string[],
    limit: number = 10,
    page: number = 1,
    sort: string = "name",
    order: string = "asc",
    filters?: any,
  ): Observable<IResponseDTO<IUser[]>> {
    const body = {
      limit,
      order,
      page,
      roles,
      sort,
      ...filters
    };
    return this.http.post<IResponseDTO<IUser[]>>(
      `${environment.apiUrl}/users/search`,
      body,
      {
        withCredentials: true,
        headers: {
          property,
        },
      }
    );
  }

  freeSearch(keyword: string) {
    return this.http.get<IUser[]>(
      `${environment.apiUrl}/users/keyword-search/${keyword}`,
      {
        withCredentials: true,
      }
    );
  }

  registerUser(user: any) {
    return this.http.post<unknown>(
      `${environment.apiUrl}/users/register`,
      user
    );
  }

  getAllUsers() {
    return this.http.get<IUser[]>(`${environment.apiUrl}/users`);
  }

  getUser(userId: string) {
    return this.http.get<IUser>(`${environment.apiUrl}/users/${userId}`, {
      withCredentials: true,
    });
  }

  addUser(body: UserReqBody.AddOne) {
    return this.http.post<IUser>(`${environment.apiUrl}/users`, body, {
      withCredentials: true,
    });
  }

  patchUser(id: string, body: UserReqBody.PatchOne) {
    return this.http.patch<IUser>(`${environment.apiUrl}/users/${id}`, body, {
      withCredentials: true,
    });
  }

  createUser(body: ITenantReq | IOwnerReq) {
    return this.http.post<IUser>(`${environment.apiUrl}/users`, body, {
      withCredentials: true,
    });
  }

  updateUser(id: string, body: ITenantReq | IOwnerReq) {
    return this.http.patch<IUser>(`${environment.apiUrl}/users/${id}`, body, {
      withCredentials: true,
    });
  }

  deleteUser(id: string) {
    return this.http.delete<unknown>(`${environment.apiUrl}/users/${id}`);
  }

  public searchEmployees(params: any) {
    return this.http.post<IResponseDTO<IEmployee[]>>(
      `${environment.apiUrl}/employees/search`,
      {
        ...params,
        withCredentials: true,
      }
    );
  }
  public getEmployees(
    roles?: string[],
    limit: number = 10,
    page: number = 1,
    sort: string = "name",
    order: string = "asc"
  ): Observable<IResponseDTO<IEmployee[]>> {
    const body = {
      limit,
      order,
      page,
      roles,
      sort,
    };
    return this.http.post<IResponseDTO<IEmployee[]>>(
      `${environment.apiUrl}/employees/search`,
      body,
      {
        withCredentials: true,
      }
    );
  }

  public searchUsersByRoleNames(roles: string[] = []): Observable<any> {
    const payload = {
      roles: roles,
    };
    return this.http.post(
      `${environment.apiUrl}/users/search-by-role-names`,
      payload,
      { withCredentials: true }
    );
  }

  public getCloudTokens(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user-tokens/cloud-tokens`, {
      withCredentials: true,
    });
  }

  public getCloudToken(provider: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/user-tokens/cloud-tokens/${provider}`,
      { withCredentials: true }
    );
  }

  public saveCloudToken(provider: string, info: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/user-tokens/cloud-tokens/${provider}`,
      info,
      { withCredentials: true }
    );
  }

  public getMicrosoftCalendars(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/microsoft-calendars`, {
      withCredentials: true,
    });
  }

  public getMicrosoftEvents(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/microsoft-events`, {
      withCredentials: true,
    });
  }

  public getMicrosoftMailFolders(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/microsoft-mail-folders`, {
      withCredentials: true,
    });
  }

  public getMicrosoftMessages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/microsoft-messages`, {
      withCredentials: true,
    });
  }

  public getOutlookMessages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/email/outlook`, {
      withCredentials: true,
    });
  }

  public refreshOutlookMessages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/email/outlook/refresh`, {
      withCredentials: true,
    });
  }

  public connectMicrosoft(): Observable<any> {
    const body = {
      callback_url: `${environment.emailUrl}/roam/connect/azure/callback`,
      roam_callback_url: `${environment.apiUrl}/cloud-tokens/azure`,
      roam_user_id: this.#user.getAuthUser().id,
    };

    return this.http.post<any>(`${environment.emailUrl}/roam/connect/azure`, body, 
      { withCredentials: true }
    );
  }

  public revokeMicrosoftToken(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/revoke-microsoft-token`, {
      withCredentials: true,
    });
  }
}

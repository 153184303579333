import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class SignatureRequestsFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    dateFrom: [],
    dateTo: [],
    document: [],
    type: [],
    requestedBy: [],
    status: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.document) filters.document = values.document;
      if (values.type) filters.type = values.type;
      if (values.requestedBy) filters.requestedBy = values.requestedBy;
      if (values.status) filters.status = values.status;

      return filters;
    })
  );

  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  document$ = this.form.get("document")?.valueChanges;
  type$ = this.form.get("type")?.valueChanges;
  requestedBy$ = this.form.get("requestedBy")?.valueChanges;
  status$ = this.form.get("status")?.valueChanges;

  reset() {
    this.form.patchValue({
      dateFrom: null,
      dateTo: null,
      document: null,
      type: null,
      requestedBy: null,
      status: null,
    });
  }
}
